<template>
  <v-card outlined class="card-vouchers">
    <v-card-subtitle>Vouchers</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" v-for="(files, index) in vouchers" :key="index">
          <v-badge color="error" overlap style="width: 100%">
            <template v-slot:badge>
              <v-icon @click="removeNewFoto(index)">mdi-minus</v-icon>
            </template>
            <v-img class="voucher-image" :src="files.url"></v-img>
          </v-badge>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-center">
          <label
              for="file-input"
              class="d-flex justify-center align-center upload-button"
          >
            <v-icon style="cursor: pointer;">mdi-plus</v-icon>
          </label>
          <input
              id="file-input"
              type="file"
              multiple
              accept="image/*"
              @change="onFileChange"
              ref="fileInput"
              class="d-none"
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "VouchersComponent",
  props: {
    vouchers: {
      type: Array,
    }
  },
  components: {},
  methods: {
    removeNewFoto(index) {
      this.$emit('remove', index)
    },
    onFileChange(e) {
      const file = e.target.files[0]
      const fileObject = {
        image: file,
        url: URL.createObjectURL(file)
      }
      this.$emit('add', fileObject)
      this.$refs['fileInput'].value = ''
    },
  }
}
</script>

<style scoped lang="scss">
.card-vouchers {
  border: 1px solid gray !important;
}

</style>