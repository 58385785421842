<template>
  <v-data-table
    class="mx-10"
    :headers="headers"
    :items="datos"
    hide-default-footer
    :page.sync="page"
    :items-per-page="5"
  >
    <template v-slot:item.voucher="{ item }">
      <BaseImagesViewerGroup :images="fotos(item.voucher)"/>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template v-slot:footer="slotProps">
      <div class="pagination d-flex justify-space-between align-center mx-auto mr-0 my-5">
        <v-btn icon @click="page--" :disabled="page === 1"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ total }}</p>
        <v-btn icon @click="page++" :disabled="total === page"><v-icon>mdi-chevron-right</v-icon></v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import BaseImagesViewerGroup from '@/components/commonComponents/BaseImagesViewerGroup'

export default {
  name: 'ThePaymentTable',
  data: () => ({
    page: 1,
    createPayment: false,
    headers: [
      { text: 'Cantidad', value: 'cantidad', sortable: true, align: 'start' },
      { text: 'Fecha', value: 'fecha', sortable: true, align: 'start' },
      { text: 'Medio de pago', value: 'medioPago', sortable: true, align: 'center' },
      { text: 'Nro de operación', value: 'numeroOperacion', sortable: true, align: 'center' },
      { text: 'Voucher', value: 'voucher', sortable: true, align: 'center' },
      { text: 'Restante', value: 'restante', sortable: true, align: 'end' },
      { text: 'Acciones', value: 'actions', align: 'center' }
    ]
  }),
  components: {
    BaseImagesViewerGroup
  },
  props: {
    listUserPayments: {
      type: Array,
      default: () => []
    },
    deuda: {
      type: [String, Number],
      default: ''
    },
    monto: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    total () {
      return this.datos.length % 5 === 0 ? parseInt(this.datos.length / 5): parseInt(this.datos.length / 5) + 1
    },
    restantes () {
      const cummulatePayments = this.listUserPayments.slice().reverse().map(item => parseInt(item.monto)).reduce((a, x, i) => [...a, x + (a[i-1] || 0)], [])
      return cummulatePayments.map(item => parseFloat(this.monto - item).toFixed(2))
    },
    datos () {
      return this.listUserPayments.slice().reverse().map((item, i) => {
        return {
          id: item.id,
          cantidad: item.monto,
          medioPago: item.medio_pago || '-',
          medioPagoId: item.medio_pago_id || '-',
          numeroOperacion: item.numero_operacion || '-',
          fecha: this.$options.filters.formatDate(item.fecha_deposito),
          voucher: item.fotos,
          restante: this.restantes[i]
        }
      })
    }
  },
  methods: {
    fotos (items) {
      return items.map(item => item.imagen)
    },
    editItem(item) {
      this.$emit('selectPaymentItem', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  width: 172px;
  height: 34px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #EAEEF2;
  border-radius: 4px;
  opacity: 1;
}
</style>
