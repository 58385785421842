<template>
  <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
      class="container"
      width="394"
      :persistent="isPersistent"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form @submit.prevent="save" ref="form">
        <div class="container__item py-11 px-10">
          <p class="container__title text-left pa-0 ma-0">Registro de nuevo pago</p>
          <p class="container__subtitle text-left pa-0 ma-0">{{ username }}</p>
        </div>
        <div class="container__item px-10">
          <v-menu
              v-model="dateMenuFechaDepositoNuevoPago"
              :close-on-content-click="false"
              :nudge-right="15"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormattedDepositoNuevoPago"
                  label="Fecha de depósito del nuevo pago"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mb-2"
                  dense
                  :rules="[rules.required]"
                  :error="errorMessages.fechaDeposito!=''"
                  :error-messages="errorMessages.fechaDeposito"
                  @focus="errorMessages.fechaDeposito = ''"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="nuevoPago.fechaDeposito"
                @input="dateMenuFechaDepositoNuevoPago = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              label="Deuda"
              v-model="deuda"
              type="number"
              dense
              readonly
              outlined
              ref="deuda"
              class="pa-0 ma-0"
          />
          <v-text-field
              label="Pago"
              v-model="nuevoPago.pago"
              type="number"
              dense
              outlined
              class="mb-2"
              ref="pago"
              :rules="[rules.required, rules.maxValue(isEditing? matricula.deuda + parseFloat(this.selectedPaymentItem.cantidad): matricula.deuda)]"
              :error="errorMessages.pago!=''"
              :error-messages="errorMessages.pago"
              @focus="errorMessages.pago = ''"
          />
          <v-menu
              v-if="matricula.deuda !== nuevoPago.pago && !isEditing"
              v-model="dateMenuFechaAlerta"
              :close-on-content-click="false"
              :nudge-right="15"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormattedFechaAlerta"
                  label="Fecha de alerta siguiente"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  class="mb-2"
                  dense
                  :rules="isEditing? []: [rules.required]"
                  :error="errorMessages.fechaAlertaSiguiente!=''"
                  :error-messages="errorMessages.fechaAlertaSiguiente"
                  @focus="errorMessages.fechaAlertaSiguiente = ''"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="nuevoPago.fechaAlertaSiguiente"
                @input="dateMenuFechaAlerta = false"
                :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
            ></v-date-picker>
          </v-menu>
          <v-select
              label="Entidad financiera"
              :items="mediosPago"
              v-model="nuevoPago.medioPago"
              item-value="id"
              item-text="nombre"
              dense
              outlined
              class="mb-2"
              :rules="[rules.required]"
              :error="errorMessages.medioPago!=''"
              :error-messages="errorMessages.medioPago"
              @focus="errorMessages.medioPago = ''"
          />
          <v-text-field
              label="Número de operación"
              v-model="nuevoPago.numeroOperacion"
              dense
              outlined
              class="mb-2"
              :error="errorMessages.numeroOperacion!=''"
              :error-messages="errorMessages.numeroOperacion"
              @focus="errorMessages.numeroOperacion = ''"
          />
          <v-card outlined class="card-vouchers">
            <v-card-subtitle>Vouchers</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" v-for="(pago, index) in nuevoPago.fotos"
                       :key="pago.id">
                  <v-badge color="error" overlap style="width: 100%;">
                    <template v-slot:badge>
                      <v-icon @click="removeFoto(index)">mdi-minus</v-icon>
                    </template>
                    <!-- <v-img class="voucher-image" :src="voucher.imagen"></v-img> -->
                    <BaseImageViewer
                        v-if="pago.imagen"
                        :imageSrc="pago.imagen"
                    />
                  </v-badge>
                </v-col>
                <v-col cols="12" sm="6" v-for="(files, index) in newFiles" :key="index">
                  <v-badge color="error" overlap style="width: 100%">
                    <template v-slot:badge>
                      <v-icon @click="removeNewFoto(index)">mdi-minus</v-icon>
                    </template>
                    <v-img class="voucher-image" :src="files.url"></v-img>
                  </v-badge>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex align-center">
                  <label
                      for="payment-file-input"
                      class="d-flex justify-center align-center upload-button"
                  >
                    <v-icon style="cursor: pointer;">mdi-plus</v-icon>
                  </label>
                  <input
                      id="payment-file-input"
                      type="file"
                      multiple
                      accept="image/*"
                      @change="onFileChange"
                      ref="paymentFileInput"
                      class="d-none"
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="container__item py-11 px-9 d-flex justify-end">
          <v-btn @click="closeCreateModal" class="container__btn mx-1">Cerrar</v-btn>
          <v-btn type="submit" :loading="loading" class="container__btn container__btn--save mx-1">Guardar</v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import {maxLength, required, requiredSelect, email, maxValue} from '@/utils/rules'
import BaseImageViewer from '@/components/commonComponents/BaseImageViewer'

export default {
  data() {
    return {
      mediosPago: [],
      dateMenuFechaDepositoNuevoPago: false,
      dateMenuFechaAlerta: false,
      nuevoPago: {
        fechaDeposito: null,
        deuda: null,
        pago: null,
        fechaAlertaSiguiente: null,
        medioPago: null,
        numeroOperacion: '',
        fotos: [],
        newFiles: [],
        deletedFotos: []
      },
      newFiles: [],
      deletedFotos: [],
      loading: false,
      rules: {
        required,
        requiredSelect,
        maxLength,
        email,
        maxValue
      },
      errorMessages: {
        fechaDeposito: '',
        deuda: '',
        pago: '',
        fechaAlertaSiguiente: '',
        medioPago: '',
        numeroOperacion: ''
      }
    }
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    matricula: {
      type: Object,
      default: () => {
      }
    },
    selectedPaymentItem: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  watch: {
    async isEditing() {
      console.log(this.selectedPaymentItem);
      if (this.isEditing) {
        const payment = await this.fetchPayment(this.selectedPaymentItem.id)
        this.nuevoPago.fechaDeposito = payment.fecha_deposito
        this.nuevoPago.pago = payment.monto
        this.nuevoPago.medioPago = payment.medio_pago_id
        this.nuevoPago.numeroOperacion = payment.numero_operacion
        this.nuevoPago.fotos = payment.fotos
      }
    }
  },
  components: {
    BaseImageViewer
  },
  computed: {
    dateFormattedDepositoNuevoPago() {
      return this.formatDate(this.nuevoPago.fechaDeposito)
    },
    dateFormattedFechaAlerta() {
      return this.formatDate(this.nuevoPago.fechaAlertaSiguiente)
    },
    deuda() {
      let deuda = this.matricula.monto_total - this.matricula.monto_pagado - this.nuevoPago.pago
      if (this.isEditing) {
        deuda += parseFloat(this.selectedPaymentItem.cantidad)
      }
      return deuda
    },
    isPersistent() {
      return this.nuevoPago.fechaDeposito !== null || this.nuevoPago.deuda !== null || this.nuevoPago.pago !== null || this.nuevoPago.fechaAlertaSiguiente !== null || this.nuevoPago.fotos !== []
    },
    isEditing() {
      return !!this.selectedPaymentItem
    }
  },
  methods: {
    async fetchMediosPago() {
      const medioPagoResponse = await axios.get('api/admin/matriculas/medios_pago/')
      this.mediosPago = medioPagoResponse.data
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    dateToInput(date) {
      return new Date(new Date(date).toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0]
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.newFiles.push({
        image: file,
        url: URL.createObjectURL(file)
      })
      this.$refs['paymentFileInput'].value = ''
    },
    removeFoto(index) {
      this.deletedFotos.push(this.nuevoPago.fotos[index])
      this.nuevoPago.fotos.splice(index, 1)
    },
    removeNewFoto(index) {
      this.newFiles.splice(index, 1)
    },
    async save() {
      if (this.isEditing) {
        await this.editPagoMatricula()
      } else {
        await this.pagarMatriculas()
      }
    },
    async editPagoMatricula() {
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.nuevoPago.fotos.length > 0 || this.newFiles.length > 0) {

          const payload = new FormData()
          payload.append('fecha_deposito', this.dateFormattedDepositoNuevoPago)
          payload.append('monto', this.nuevoPago.pago)
          payload.append('fecha_alerta', this.dateToInput(this.nuevoPago.fechaAlertaSiguiente))
          payload.append('matricula', this.matricula.id)
          payload.append('medio_pago_id', this.nuevoPago.medioPago)
          payload.append('numero_operacion', this.nuevoPago.numeroOperacion)
          this.newFiles.forEach((newFile, i) => {
            payload.append(`fotos[${i}]imagen`, newFile.image)
          })
          this.deletedFotos.forEach((deletedFoto) => {
            payload.append(`fotos_eliminadas_ids`, deletedFoto.id)
          })
          try {
            await axios.patch(`api/admin/matriculas/pagos/${this.selectedPaymentItem.id}/`, payload)
            this.$emit('reloadTable')
            this.closeCreateModal()
          } catch (e) {
            console.log(e.response.data)
            this.errorMessages.fechaDeposito = e.response.data.fecha_deposito || ''
            this.errorMessages.monto = e.response.data.monto || ''
            this.errorMessages.numeroOperacion = e.response.data.numero_operacion || ''
            // this.errorMessages.matricula = e.response.data.matricula || ''
            this.errorMessages.fechaAlertaSiguiente = e.response.data.fecha_alerta || ''
          }
        } else {
          this.$root.$confirm({
            title: '¡Ups!',
            message: 'Debe ingresar al menos un voucher.'
          })
        }
      } else {
        this.$root.$confirm({
          title: 'Mensaje',
          message: 'Asegúrese de llenar correctamente todos los campos.'
        })
      }
      this.loading = false
    },
    async pagarMatriculas() {
      // console.log(this.nuevoPago.fechaAlertaSiguiente)
      // console.log(this.dateToInput(this.nuevoPago.fechaAlertaSiguiente))
      if (this.$refs.form.validate()) {
        this.loading = true
        if (this.nuevoPago.fotos.length > 0 || this.newFiles.length > 0) {
          this.nuevoPago.newFiles = this.newFiles
          this.nuevoPago.deletedFotos = this.deletedFotos
          const payload = new FormData()
          payload.append('fecha_deposito', this.dateFormattedDepositoNuevoPago)
          payload.append('monto', this.nuevoPago.pago)
          payload.append('fecha_alerta', this.dateToInput(this.nuevoPago.fechaAlertaSiguiente))
          payload.append('matricula', this.matricula.id)
          payload.append('medio_pago_id', this.nuevoPago.medioPago)
          payload.append('numero_operacion', this.nuevoPago.numeroOperacion)
          this.nuevoPago.newFiles.forEach((newFile, i) => {
            payload.append(`fotos[${i}]imagen`, newFile.image)
          })
          try {
            await axios.post(`api/admin/matriculas/pagos/`, payload)
            this.$emit('reloadTable')
            this.closeCreateModal()
          } catch (e) {
            this.errorMessages.fechaDeposito = e.response.data.fecha_deposito || ''
            this.errorMessages.monto = e.response.data.monto || ''
            this.errorMessages.numeroOperacion = e.response.data.numero_operacion || ''
            this.errorMessages.fechaAlertaSiguiente = e.response.data.fecha_alerta || ''
          }
        } else {
          this.$root.$confirm({
            title: '¡Ups!',
            message: 'Debe ingresar al menos un voucher.'
          })
        }
      } else {
        this.$root.$confirm({
          title: 'Mensaje',
          message: 'Asegúrese de llenar correctamente todos los campos.'
        })
      }
      this.loading = false
    },
    resetForm() {
      this.nuevoPago.fotos = [],
          this.nuevoPago.deuda = null,
          this.nuevoPago.pago = null,
          this.nuevoPago.fechaDeposito = null,
          this.nuevoPago.fechaAlertaSiguiente = null
      this.nuevoPago.medioPago = null
      this.nuevoPago.numeroOperacion = ''
      this.newFiles = []
      this.deletedFotos = []
    },
    async closeCreateModal() {
      // this.$refs.form.reset()
      await this.resetForm()
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async fetchPayment(paymentId) {
      const paymentResponse = await axios.get(`api/admin/matriculas/pagos/${paymentId}/`)
      return paymentResponse.data
    }
  },
  async mounted() {
    await this.fetchMediosPago()
  }
}
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid red !important;
}

.container {
  max-height: 757px !important;

  &__title {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__subtitle {
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__item {
    flex-basis: 33%;
    width: 100%;
  }

  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;

    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }

  &__input {
    height: 68px !important;
  }
}
</style>
