<template>
  <v-dialog
      class="container"
      width="394"
      persistent
      v-bind="$attrs"
      v-on="$listeners"
      @close="closeModal"
  >
    <v-card class="container__card d-flex flex-column rounded-lg" width="100%">
      <v-form ref="form">
        <div class="container__item py-11 px-10">
          <p class="container__title text-left pa-0 ma-0">
            {{ isEditing ? 'Editar matricula' : 'Nueva matricula' }}
          </p>
          <p v-if="user" class="container__subtitle text-left pa-0 ma-0">{{ user.display_name }}</p>
        </div>
        <div class="container__item px-10">
          <v-autocomplete
              v-model="section"
              label="Sección"
              :items="availableSections"
              item-value="id"
              item-text="nombre"
              dense
              outlined
              class="mb-2"
              :rules="[rules.required]"
              :error="errorMessages.medio_pago !== ''"
              :error-messages="errorMessages.medio_pago"
              @focus="errorMessages.medio_pago = ''"
          />
          <v-text-field
              v-if="!isEditing"
              v-model="montoPagado"
              label="Monto pagado"
              placeholder="Escriba el monto pagado..."
              type="number"
              dense
              outlined
              class="mb-2"
              :rules="[rules.required]"
              ref="payed"
              :error="errorMessages.monto_pagado!=''"
              :error-messages="errorMessages.monto_pagado"
              @focus="errorMessages.monto_pagado = ''"
          />
          <v-text-field
              v-model="montoTotal"
              label="Monto total"
              placeholder="Escriba el monto total..."
              dense
              outlined
              type="number"
              class="mb-2"
              :rules="[rules.required]"
              ref="total"
              :error="errorMessages.monto_total!=''"
              :error-messages="errorMessages.monto_total"
              @focus="errorMessages.monto_total = ''"
          />
          <v-checkbox
            v-model="pagoCuotas"
            label="Pago en cuotas"
            dense
            class="pa-0 ma-0"
          ></v-checkbox>
          <template v-if="!isEditing">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="15"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="dateFormattedDeposito"
                    label="Fecha de depósito"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mb-2"
                    dense
                    :rules="[rules.required]"
                    ref="payed"
                ></v-text-field>
              </template>
              <v-date-picker
                  @input="depositoDateMenu = false"
                  v-model="depositoDate"
              ></v-date-picker>
            </v-menu>
            <v-select
                v-model="medioPago"
                label="Entidad financiera"
                :items="mediosPago"
                item-value="id"
                item-text="nombre"
                dense
                outlined
                class="mb-2"
                :rules="[rules.required]"
                :error="errorMessages.medio_pago !== ''"
                :error-messages="errorMessages.medio_pago"
                @focus="errorMessages.medio_pago = ''"
            />
            <v-text-field
                v-model="operationNumber"
                label="Número de operación"
                dense
                outlined
                class="mb-2"
                :error="errorMessages.numero_operacion !== ''"
                :error-messages="errorMessages.numero_operacion"
                @focus="errorMessages.numero_operacion = ''"
            />

            <vouchers-component
                class="mb-5"
                :vouchers="vouchers"
                @remove="vouchers.splice($event, 1)"
                @add="vouchers.push($event)"
            ></vouchers-component>

            <v-checkbox
                v-model="activateAlerts"
                label="¿Deseas activar las alertas?"
                dense
                class="pa-0 ma-0"
                :class="activateAlerts ? 'mb-2': ''"
            ></v-checkbox>
            <v-menu
                v-if="activateAlerts"
                :close-on-content-click="false"
                :nudge-right="15"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="dateFormattedAlert"
                    label="Fecha de alerta de pago"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mb-2"
                    dense
                    :rules="[rules.required]"
                    ref="payed"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="alertDate"
                  @input="alertDateMenu = false"
                  :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString()"
              ></v-date-picker>
            </v-menu>
            <v-checkbox
                v-model="apps"
                label="Banqueo VIP"
                value="Banqueo"
                class="pa-0 ma-0"
                dense
            ></v-checkbox>
            <v-checkbox
                v-model="apps"
                label="Flashcard VIP"
                value="Flashcards"
                class="pa-0 ma-0"
                dense
            ></v-checkbox>
          </template>
        </div>
        <div class="container__item py-11 px-9 d-flex justify-end">
          <v-btn @click="closeModal" class="container__btn mx-1">Cerrar</v-btn>
          <v-btn @click="save" :loading="loading" class="container__btn container__btn--save mx-1">Guardar
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import {maxLength, required, requiredSelect, email, minValue, maxValue} from '@/utils/rules'
import VouchersComponent from "../../commonComponents/VouchersComponent";
import {mapActions} from "vuex";

export default {
  name: "AddMatriculaModal",
  components: {
    VouchersComponent
  },
  props: ['user', 'activatorText', 'matriculaToEdit'],
  inheritAttrs: false,
  data() {
    return {
      dialog: false,
      depositoDateMenu: false,
      alertDateMenu: false,
      depositoDate: null,
      alertDate: null,
      section: '',
      montoPagado: 0,
      medioPago: null,
      pagoCuotas: false,
      montoTotal: 0,
      operationNumber: '',
      vouchers: [],
      activateAlerts: false,
      apps: [],
      loading: false,
      rules: {
        required,
        requiredSelect,
        maxLength,
        email,
        minValue,
        maxValue
      },
      errorMessages: {
        monto_pagado: '',
        monto_total: '',
        medio_pago: '',
        numero_operacion: '',
      },
      mediosPago: [],
      sections: [],
    }
  },
  watch: {
    isEditing() {
      if (this.isEditing) {
        this.montoTotal = this.matriculaToEdit.monto_total
        this.montoPagado = this.matriculaToEdit.monto_pagado
        this.pagoCuotas = this.matriculaToEdit.pago_cuotas
        this.section = this.matriculaToEdit.seccion.id
      }
    }
  },
  computed: {
    dateFormattedDeposito() {
      return this.formatDate(this.depositoDate)
    },
    dateFormattedAlert() {
      return this.formatDate(this.alertDate)
    },
    isEditing() {
      return !!this.matriculaToEdit
    },
    availableSections() {
      if (this.isEditing) {
        return [
          ...this.sections,
          this.matriculaToEdit.seccion
        ]
      }
      return this.sections
    }
  },
  methods: {
    ...mapActions(['fetchAlerts']),
    async fetchMediosPago() {
      const medioPagoResponse = await axios.get('api/admin/matriculas/medios_pago/')
      this.mediosPago = medioPagoResponse.data
    },
    async fetchSections() {
      const sectionsResponse = await axios.get(`api/admin/matriculas/users/${this.user.id}/secciones_disponibles`)
      this.sections = sectionsResponse.data
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    closeModal() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.vouchers = []
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.pagoCuotas && Number(this.montoPagado) === Number(this.montoTotal)) {
        this.errorMessages.monto_total = `El monto pagado (${this.montoPagado}) no puede ser igual al total si la matricula se pagará en cuotas.`
        return
      }
      if (!this.pagoCuotas && Number(this.montoPagado) !== Number(this.montoTotal)) {
        this.errorMessages.monto_total = `Si se paga al contado el monto pagado (${this.montoPagado}) debe ser igual al monto total.`
        return
      }
      try {
        this.loading = true
        if (!this.isEditing) {
          await this.createMatricula()
        } else {
          await this.updateMatricula()
        }
        this.loading = false
        this.closeModal()
        this.fetchAlerts()
        this.$root.$confirm({
          title: 'Mensaje',
          message: !this.isEditing? 'Matricula creada': 'Matricula editada',
          acceptText: 'Aceptar'
        })

      } catch (error) {
        this.loading = false
        this.errorMessages.monto_pagado = error.response.data.monto_pagado || ''
        this.errorMessages.monto_total = error.response.data.monto_total || ''
        if (error.response.data && error.response.data.user_seccion) {
          this.$root.$confirm({
            title: '¡Ups!',
            message: error.response.data.user_seccion[0],
            acceptText: 'Aceptar'
          })
          return
        }
        if (!this.errorMessages.monto_pagado && !this.errorMessages.monto_total) {
          this.$root.$confirm({
            title: '¡Ups!',
            message: "No se pudo matricular al usuario. Contacte a soporte.",
            acceptText: 'Aceptar'
          })
        }
      }
    },
    async createMatricula () {
        const payload = {
          deposito_deposito: this.depositoDate,
          fecha_alerta: this.alertDate,
          seccion: this.section,
          monto_pagado: this.montoPagado,
          monto_total: this.montoTotal,
          pago_cuotas: this.pagoCuotas,
          numero_operacion: this.operationNumber,
          usuario: this.user.id,
          activateAlerts: this.activateAlerts,
          apps: this.apps,
        }
        const matriculaResponse =  await this.axios.post('api/admin/matriculas/matriculas/', payload)
        this.$emit('matriculated', matriculaResponse.data)
    },
    async updateMatricula () {
        const payload = {
          seccion: this.section,
          monto_total: this.montoTotal,
          pago_cuotas: this.pagoCuotas,
          usuario: this.user.id,
        }
        const matriculaResponse = await this.axios.put(`api/admin/matriculas/matriculas/${this.matriculaToEdit.id}/`, payload)
        this.$emit('matriculated', matriculaResponse.data)
    }
  },
  mounted() {
    this.fetchMediosPago()
    this.fetchSections()
  }
}
</script>

<style scoped lang="scss">
.container {
  max-height: 757px !important;

  &__title {
    font-size: 17px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__subtitle {
    font: normal normal 300 14px/21px Poppins;
    letter-spacing: 0px;
    color: #455A64;
    opacity: 1;
  }

  &__item {
    flex-basis: 33%;
    width: 100%;
  }

  &__btn {
    text-transform: none !important;
    background: #FFF !important;
    border: 1px solid #707070 !important;
    font-size: 16px !important;
    color: #999999;
    box-shadow: none !important;

    &--save {
      background: #009EFB !important;
      color: #FFF;
    }
  }

  &__input {
    height: 68px !important;
  }
}

</style>
