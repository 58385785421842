<template>
  <div>
    <v-card class="mb-7 pb-7">
      <v-card-text class="px-5 pt-5 pb-1">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Informacion de usuario</h3>
        <h6 class="subtitle-2 font-weight-light">{{ userDetail.username }}</h6>
      </v-card-text>
      <v-card-text>
        <v-form @submit.prevent="doEditUser" ref="form" lazy-validation="">
          <v-row class="px-6">
            <v-col cols="12" md="6">
              <p class="pa-0 ma-0">Nombre</p>
              <v-text-field
                  v-model="currentUser.first_name"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  class="mx-auto"
                  :error="errorMessages.currentUser.first_name!=''"
                  :error-messages="errorMessages.currentUser.first_name"
                  @focus="errorMessages.currentUser.first_name = ''"
                  :rules="[rules.required]"
              />
              <p class="pa-0 ma-0">Apellido</p>
              <v-text-field
                  v-model="currentUser.last_name"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  class="mx-auto"
                  :error="errorMessages.currentUser.last_name!=''"
                  :error-messages="errorMessages.currentUser.last_name"
                  @focus="errorMessages.currentUser.last_name = ''"
                  :rules="[rules.required]"
              />
              <p class="pa-0 ma-0">Número de celular:</p>
              <v-text-field
                  v-model="currentUser.celular"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  type="number"
                  class="mx-auto"
                  :rules="[rules.required, rules.minLength(9)]"
                  :error="errorMessages.currentUser.celular !== ''"
                  :error-messages="errorMessages.currentUser.celular"
                  @focus="errorMessages.currentUser.celular = ''"
              />
              <p class="pa-0 ma-0">Region</p>
              <v-select
                  :items="regions"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  v-model="currentUser.region"
              />
              <!-- :rules="[rules.required]" -->
            </v-col>
            <v-col cols="12" md="6">
              <p class="pa-0 ma-0">Número de carnet CMP:</p>
              <v-text-field
                  v-model="currentUser.carnet_cmp"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  class="mx-auto"
                  :rules="currentUser.carnet_cmp ? [rules.maxLength(8)]: []"
              />
              <!-- :rules="[rules.required, rules.maxLength(8)]" -->
              <p class="pa-0 ma-0">Fecha de nacimiento:</p>
              <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :rules="[rules.required]"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dateFormatted"
                      dense
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="currentUser.fecha_nacimiento"
                    @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
              <p class="pa-0 ma-0">Universidad de estudio</p>
              <v-select
                  v-model="currentUser.institucion"
                  :items="instituciones"
                  item-text="nombre"
                  item-value="id"
                  outlined
                  dense
                  append-icon="mdi-square-edit-outline"
                  :rules="[rules.required]"
              >
              </v-select>
              <p class="pa-0 ma-0">Número de documento:</p>
              <v-text-field
                  v-model="currentUser.documento"
                  dense
                  outlined
                  append-icon="mdi-square-edit-outline"
                  class="mx-auto"
                  :rules="[rules.maxLength(12), rules.minLength(8)]"
              />
              <div class="d-flex justify-end align-center mx-6">
                <v-btn class="mr-4 mr-2" color="#FFF" style="text-transform: none;" @click="doDeleteUser">Eliminar
                </v-btn>
                <v-btn class="mr-4 mr-2" type="submit" :loading="loading" color="success" style="text-transform: none;">
                  Guardar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text v-if="currentMatriculaName" class="px-5 pb-1 d-inline-flex justify-space-between">
        <div>
          <h3 v-if="currentMatriculaName"
              class="title blue-grey--text text--darken-2 font-weight-regular d-inline-flex">
            Información de matrícula {{ currentMatriculaName }}</h3>
          <!-- menu to choose matricula -->
          <v-menu
              bottom
              left
              offset-y
              origin="top right"
              transition="scale-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" class="mr-1">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(item, i) in listOfMatriculas" :key="i" @click="fetchMatricula(item.id)">
                <v-list-item-title>{{ item.nombre }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- menu to choose matricula -->
        </div>

        <div>
          <v-btn
              color="green"
              small
              fab
              class="mx-3"
              @click="matriculaToEdit=currentMatricula; showAddMatriculaModal=true"
          >
            <v-icon color="white">mdi-pencil</v-icon>
          </v-btn>

          <v-btn
              color="primary"
              small
              fab
              @click="showAddMatriculaModal=true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-if="currentMatriculaName">
        <v-row class="px-6">
          <v-col cols="12" md="6">
            <p class="pa-0 ma-0">Nombre de la sección</p>
            <v-text-field
                v-model="currentMatricula.seccion.nombre"
                dense
                outlined
                append-icon="mdi-square-edit-outline"
                readonly
            />
            <p class="pa-0 ma-0">Pago Minimo</p>
            <v-text-field
                v-model="currentMatricula.seccion.pago_minimo"
                dense
                outlined
                readonly
                append-icon="mdi-square-edit-outline"
            />
          </v-col>
          <v-col cols="12" md="6">
            <p class="pa-0 ma-0">Fecha de inicio</p>
            <v-menu
                v-model="dateMenu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFormattedInicio"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    readonly
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="currentMatricula.seccion.fecha_inicio"
                  @input="dateMenu1 = false"
              ></v-date-picker>
            </v-menu>
            <p class="pa-0 ma-0">Fecha de fin</p>
            <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateFormattedFin"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    dense
                    readonly
                    outlined
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="currentMatricula.seccion.fecha_fin"
                  @input="dateMenu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card v-if="currentMatriculaName" flat width="92%" class="mx-auto rounded-lg"
              style="border: 1px solid #DADEE3;">
        <v-card-text class="pa-5">
          <div class="d-md-flex align-start justify-space-between">
            <h3 class="title font-weight-regulRar">Pagos registrados
              {{ currentUser.documento ? `(${currentUser.documento})` : '' }}</h3>
            <v-btn :disabled="currentMatricula.deuda === 0" @click="createPayment = true" height="42" width="147"
                   color="primary" class="rounded-pill mr-6" style="text-transform: none !important">
              Crear pago
            </v-btn>
            <!-- <v-spacer></v-spacer> -->
          </div>
        </v-card-text>
        <ThePaymentTable :listUserPayments="listUserPayments" :deuda="currentMatricula.deuda"
                         :monto="currentMatricula.monto_total" @selectPaymentItem="selectPaymentItem"></ThePaymentTable>
      </v-card>
      <TheCreateNewPayment v-model="createPayment" @reloadTable="reloadTable" :username="currentUser.username"
                           :matricula="currentMatricula" @close="createPayment=false; selectedPaymentItem=null"
                           :selected-payment-item="selectedPaymentItem"/>
    </v-card>
    <div class="text-center" v-if="matriculas.length === 0">
      <v-btn
          @click="showAddMatriculaModal=true"
          color="primary"
          style="text-transform: none !important"
      >
        Agregar matricula
      </v-btn>
    </div>
    <add-matricula-modal
        v-if="currentUser.id"
        v-model="showAddMatriculaModal"
        :user="currentUser"
        :matriculaToEdit="matriculaToEdit"
        @matriculated="matriculaCreated"
        @close="closeMatriculaModal"
    >
    </add-matricula-modal>
  </div>
</template>

<script>
import {required, percentage, equalToLength, minLength, maxLength} from '@/utils/rules.js'
import TheCreateNewPayment from '@/components/dashboardComponents/TheCreateNewPayment'
import ThePaymentTable from '@/components/dashboardComponents/ThePaymentTable'
import AddMatriculaModal from '@/components/dashboardComponents/user/AddMatriculaModal'
import axios from 'axios'
import {mapActions, mapState} from 'vuex';

export default {
  data: () => ({
    app: [],
    page: 1,
    pageCount: 0,
    show1: false,
    checkbox: "",
    loading: false,
    dateMenu: false,
    rules: {
      required,
      percentage,
      equalToLength,
      minLength,
      maxLength,
    },
    selectedPaymentItem: null,
    createPayment: false,
    currentUser: {
      first_name: '',
      last_name: '',
      celular: '',
      distrito: null,
      fecha_nacimiento: '',
      institucion: null,
      carnet_cmp: '',
      documento: '',
    },
    currentMatricula: {
      seccion: {
        id: null,
        nombre: '',
        precio: null,
        pago_minimo: null,
        fecha_inicio: '',
        fecha_fin: ''
      }
    },
    errorMessages: {
      currentUser: {
        first_name: '',
        last_name: '',
        carnet_cmp: '',
        celular: '',
        distrito: '',
        carnet: '',
        institucion: ''
      }
    },
    instituciones: null,
    regions: [],
    headerArray: [
      {text: 'Cantidad', value: 'cantidad', align: 'start'},
      {text: 'Fecha', value: 'fecha', sortable: false, align: 'end'},
      {text: 'Seccion', value: 'inscripcion', sortable: false, align: 'end'},
      {text: 'Restantes', value: 'restantes', sortable: false, align: 'end'}
    ],
    listUserPayments: [],
    currentMatriculaName: '',
    matriculas: [],
    currentListPayments: [],
    dateMenu1: false,
    dateMenu2: false,
    canales: null,
    showAddMatriculaModal: null,
    matriculaToEdit: null,
    matriculaModalTimeout: null
  }),
  components: {
    TheCreateNewPayment,
    ThePaymentTable,
    AddMatriculaModal
  },
  computed: {
    ...mapState('usuarios', ['userDetail']),
    dateFormatted() {
      if (this.currentUser.fecha_nacimiento) {
        return this.formatDate(this.currentUser.fecha_nacimiento)
      } else {
        return ''
      }
    },
    dateFormattedInicio() {
      if (this.currentMatricula.seccion.fecha_inicio) {
        return this.formatDate(this.currentMatricula.seccion.fecha_inicio)
      } else {
        return ''
      }
    },
    dateFormattedFin() {
      if (this.currentMatricula.seccion.fecha_fin) {
        return this.formatDate(this.currentMatricula.seccion.fecha_fin)
      } else {
        return ''
      }
    },
    listOfMatriculas() {
      return this.matriculas.map(item => ({
        id: item.id,
        nombre: item.seccion.nombre
      }))
    }
  },
  methods: {
    ...mapActions('usuarios', ['fetchUserDetail']),
    selectPaymentItem(item) {
      this.selectedPaymentItem = item
      this.createPayment = true
    },
    formatDate(date) {
      return new Date(date).toISOString().split('T')[0].replaceAll('-', '/')
    },
    async fetchMatriculasByUser(userId) {
      const response = await axios.get(`api/admin/matriculas/users/${userId}/matriculas/`)
      this.matriculas = [...response.data]
    },
    async fetchMatricula(matriculaId) {
      const matricula = await axios.get(`api/admin/matriculas/matriculas/${matriculaId}/`)
      this.currentMatricula = matricula.data
      this.currentMatriculaName = this.currentMatricula.seccion.nombre
    },
    async fetchInstituciones() {
      const institucionesResponse = await this.$http.get('api/instituciones/')
      this.instituciones = institucionesResponse.data
    },
    async fetchRegions() {
      const regionsResponse = await this.$http.get('api/ciudades/region/')
      this.regions = regionsResponse.data
    },
    async updateUser(userId, payload) {
      const userResponse = await axios.patch(`api/admin/matriculas/users/${userId}/`, payload)
      this.currentUser = userResponse.data
    },
    async doEditUser() {
      // if (this.$refs.form.validate()) {
      this.loading = true
      try {
        await this.updateUser(this.currentUser.id,
            {
              first_name: this.currentUser.first_name || '',
              last_name: this.currentUser.last_name || '',
              celular: this.currentUser.celular || null,
              region: this.currentUser.region || '',
              carnet_cmp: this.currentUser.carnet_cmp,
              institucion: this.currentUser.institucion || null,
              fecha_nacimiento: this.currentUser.fecha_nacimiento || null,
              documento: this.currentUser.documento || null
            }
        )
        await this.$root.$confirm({
          title: '¡Felicidades!',
          message: 'Se ha actualizado tu informacion de usuario.',
          acceptText: 'Aceptar'
        })
      } catch (e) {
        this.errorMessages.currentUser.first_name = e.response.data.first_name || ''
        this.errorMessages.currentUser.last_name = e.response.data.last_name || ''
        this.errorMessages.currentUser.celular = e.response.data.celular || ''
        this.errorMessages.currentUser.distrito = e.response.data.distrito || ''
        this.errorMessages.currentUser.fecha_nacimiento = e.response.data.fecha_nacimiento || ''
        this.loading = false
        await this.$root.$confirm({
          title: '¡Lo sentimos!',
          message: 'Ha ocurrido un error actualizando su informacion de usuario. Intentelo nuevamente en unos minutos.',
          acceptText: 'Aceptar'
        })
      } finally {
        this.loading = false
      }
      // }
    },
    async deleteUser() {
      await this.$http.delete(`api/admin/matriculas/users/${this.currentUser.id}/`)
    },
    async doDeleteUser() {
      try {
        const confirm = await this.$root.$confirm({
          title: '¿Estás seguro?',
          message: 'Se eliminará de forma permanente este usuario.',
          acceptText: "Si",
          cancelText: "No"
        })
        if (confirm) {
          await this.deleteUser()
          await this.$router.push({name: 'Users'})
        }
      } catch (e) {
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    },
    async reloadTable() {
      const payments = await axios.get(`api/admin/matriculas/matriculas/${this.currentMatricula.id}/ver_pagos/`)
      this.listUserPayments = payments.data
      await this.fetchMatricula(this.currentMatricula.id)
    },
    async matriculaCreated(matricula) {
      await this.fetchMatriculasByUser(this.currentUser.id)
      await this.fetchMatricula(matricula.id)
    },
    async matriculaEdited(matricula) {
      await this.fetchMatriculasByUser(this.currentUser.id)
      await this.fetchMatricula(matricula.id)
    },
    closeMatriculaModal() {
      this.matriculaModalTimeout = null
      this.showAddMatriculaModal=false;
      this.matriculaModalTimeout = setTimeout(() => {
        this.matriculaToEdit=null;
      }, 100)
    }
  },
  watch: {
    'currentMatricula.id': async function () {
      const payments = await axios.get(`api/admin/matriculas/matriculas/${this.currentMatricula.id}/ver_pagos/`)
      this.listUserPayments = payments.data
    }
  },
  async created() {
    const userId = this.$route.params.id
    await this.fetchInstituciones()
    await this.fetchRegions()
    await this.fetchUserDetail(userId)
    await this.fetchMatriculasByUser(userId)
    if (this.matriculas.length > 0) {
      await this.fetchMatricula(this.matriculas[0].id)
      const payments = await axios.get(`api/admin/matriculas/matriculas/${this.currentMatricula.id}/ver_pagos/`)
      this.listUserPayments = payments.data
    }
    this.currentUser = this.userDetail
    // handle rules errors
    if (this.currentUser.celular === null) {
      this.currentUser.celular = ''
    }
    this.$emit('addBread')
  }
}
</script>

<style lang="scss" scoped>
.vouchers {
  &__container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    opacity: 1;
  }
}
</style>
